export const constants = {
  RT: "TECHNICALMANAGER",
  GESTIONNAIRE: "GESTIONNAIRE",
  PERMISSION_AM: "AFFAIR_MANAGER",
  PERMISSION_PM: "PLANING_MANAGER",
  PERMISSION_TRE: "TECHNICAL_REPORT_EDITOR",
  PERMISSION_CRE: "CLIENT_REPORT_EDITOR",
  PERMISSION_EC: "ELEMENT_CONSULTATION",
  PERMISSION_BILL: "BILLING",
  PERMISSION_PAYMENT: "PAYMENT",
  ADMIN: "OWNER",
  TEAM: "TEAM",
  COMPTA: "BILLMANAGER",

  UNKNOWN: 0,
  IN_STOCK: 1,
  ON_SITE: 2,
  SUPPLIER: 3,

  MAPCENTER: [48, 2.75],
  PULSE_FAST: "fast",

  INTERVENTION_ENTRETIEN: 1,
  INTERVENTION_URGENCE: 2,
  INTERVENTION_SAV: 3,
  INTERVENTION_CHANTIER: 4,
  INTERVENTION_DECHETTERIE: 5,
  INTERVENTION_DEPOT: 6,
  INTERVENTION_ATELIER: 7,
  INTERVENTION_FOURNISSEUR: 8,

  MATERIAL_CLASS: "ConstructionMaterial",
  MACHINERY_CLASS: "ConstructionMachinery",
  DOCUMENT_CLASS: "ConstructionDocument",
  ADMIN_DOC_CLASS: "AdministrationDocument",


  INTERVENTION_CANCEL: 1,
  INTERVENTION_UNVALIDATE: 2,
  INTERVENTION_PROBATORY: 3,
  INTERVENTION_SEND: 4,
  INTERVENTION_RECEIVED: 5,
  INTERVENTION_SCHEDULED: 6,
  INTERVENTION_PROCESSED: 7,
  INTERVENTION_FINISH: 8,
  INTERVENTION_VALIDATE: 10,
  INTERVENTION_WRITTEN: 11,
  INTERVENTION_BILLED: 12,
  INTERVENTION_PAID: 13,

  CANCEL: 0,
  PENDING: 1,
  SEND: 2,
  RECEIVED: 3,
  TOSCHEDULED: 4,
  SCHEDULED: 5,
  PROCESSING: 6,
  DONE: 7,
  WRITING: 8,
  FINISHED: 9,

  STATUS_CANCEL: "CANCEL",
  STATUS_PENDING: "PENDING",
  STATUS_SEND: "SEND",
  STATUS_RECEIVED: "RECEIVED",
  STATUS_TOSCHEDULED: "TOSCHEDULED",
  STATUS_SCHEDULED: "SCHEDULED",
  STATUS_PROCESSING: "PROCESSING",
  STATUS_DONE: "DONE",
  STATUS_WRITING: "WRITING",
  STATUS_FINISHED: "FINISHED",


  S1: 1000,
  M1: 60000,
  H1: 3600000,
  H24: 86400000,
  READY: "pret",
  NULL: "-",
  ABS_COMPANYID: 1,
  DEFAULT_MAP_PIN_NUMBER: 500,
  DEFAULT_DURATION: 30 * 60,
  REPAIR: "Repair",
  WORK: "Work",

  MAX_LENGTH: 4096,

  roofer: "Couverture",
  solar: "Panneau solaire",

}
export const text = {

  STATUS_TRADUCTION: {
    CANCEL: "Annulée",
    PENDING: "Suspendue",
    SEND: "Envoyée au prestataire",
    RECEIVED: "Reçue par le prestataire",
    TOSCHEDULED: "Reçue par le prestataire",
    SCHEDULED: "Planifiée",
    PROCESSING: "En cours d'intervention",
    DONE: "En cours de rédaction",
    WRITING: "En cours de rédaction",
    FINISHED: "Fini",
  },

  ID: "id",
  PHONE_NUMBER: "Numéro",
  CLIENT: "Client",
  SITE: "Site",
  STATUS: "Statut",
  DESCRIPTION: "Description",
  TEAM: "Equipe",
  INTERVENTION_START: "Début d'intervention",
  INTERVENTION_END: "Fin d'intervention",
  ADDRESS: "Adresse",
  ADDRESS2: "Complément Adresse",
  ZIPCODE: "Code Postal",
  TOWN: "Ville",
  SITE_PHONE: "Numéro de téléphone du site",
  ZONE: "Secteur",
  DATE: "Date",
  FROM: "De",

  POI: "Plan des éléments signalés",
  NO_NAME: "Sans nom",
  TO_DEFINE: "À définir",
  SNACKBAR_SUCCESS: "Opération réalisée avec succès",

  TD_NAME: "Nom",
  TD_FUNCTION: "Fonction",
  MAIL: "Adresse Mail",
  TD_COMMENT: "Signalements / Commentaires",

  TH_CONCLUSION: "Conclusion",

  TH_CONTRIBUTOR: "Intervenant",
  TH_TECHNICAL_MANAGER: "Responsable Technique",
  TH_ADMINISTRATOR: "Gestionnaire",
  TH_IMAGES: "Images",

  NO_OPERATION_TRANSMITTED: "Aucune opération n'a été transmise ici pour cette intervention.",
  H1_RISK_ANALYSIS: "Analyse de risque",
  H1_REPORTED_INFO: "Informations remontées par le client",
  NO_RISK_ANALYSIS: "Aucune analyse de risque n'a été transmise",
  H1_GENERAL_INFO: "Informations générales",
  H2_DOCUMENTS: "Documents",
  SIGNATURE: "Signature",
  OBJECT: "Objet",
  RESUME: "Résumé",
  CHANGED_STATUS: "Statut modifié avec succès.",

  PLAN: "Plan du site - Vue Satellite",

  CLIENT_SITE_LIST: "Liste des sites du client",
  PERF_SERVICE_PROVIDER: "Statistique du prestataire",

  INTERVENTION_LINKED: "Interventions liées à la commande",
  DEVIS_LINKED: "Commandes liées à la demande",

  COMMAND_NUM: "Commande n°",
  REQUEST_NUM: "Demande n°",
  COMMAND_REF: "Référence de la commande",
  TOOLTIP_CMD_REF: "Éditer le numéro du bon de commande",
  TOOLTIP_REQUEST_REF: "Éditer le numéro de cette demande",
  JOBTYPE: "Corps de métier",

  BILL_CMD: "Devis / Bon de Commande",
  REQUEST_TITLE: "Intitulé de la demande",
  ENTITLED: "Intitulé de la demande",

  AMOUNT: "Montant",
  DEPOSIT: "Acompte",
  CLIENT_2: "Client",
  SITE_2: "Site",
  REQUEST_BY: "Demande de",
  REQUEST_CONTENT: "Contenu de la demande",

  RECEPTION_DATE: "Réception",

  NOTES_AND_COMMENTS: "Notes et Commentaires",
  PAID: "Payée",

  INTERVENTION_NUMBER: "Numéro",
  FILE_TYPE: "Nature du Dossier",
  CONTACTS: "Contacts",
  INSURANCE: "Assurance",

  TOOLTIP_LIST: "Affiche la liste des échanges",
  TOOLTIP_ADD_INTERV: "Ajoute une intervention à la commande",
  TOOLTIP_SUPPLY: "Affiche la liste des approvisionnements des interventions de la commande",
  TOOLTIP_CLIENT_CHAT: "Affiche la liste des échanges avec le client",
  TOOLTIP_SAVED_REPORT_LIST: "Affiche la liste des rapports sauvegardés",
  TOOLTIP_ADD_FILE: "Ajouter un fichier",

  ADD_BUTTON: "Ajouter",

  CREATE_INTERV: "Créer une nouvelle intervention dans cette commande",
  CREATE_INTERV_IN_REQUEST: "Créer une nouvelle commande dans cette demande",

  NO_REPORT_SAVED: "Aucun rapport n'a été enregistré.",
  NO_RIGHT: "Vous n'avez pas les droits requis pour éditer ce champ.",
  H2_CONTACT_REPORT: "Contacts et échanges",
  ADD_MESSAGE: "Ajouter un échange : ",


  SNACKBAR_SAVED: "La modification a bien été enregistrée 😁",
  SNACKBAR_ERROR: "Une erreur est survenue 😕",
  TOOLTIP_PDF_VISIBLE_OPERATION: "Visibilité de l'opération dans le rapport Pdf",
  EXPORT_OPERATION: "Exporter l'opération dans une autre intervention",
  INVISIBLE: "Invisible",
  EXPORT_TO: "Exporter vers ",
  EXPORT: "Exporter",
  DEGRADATION_STATE: "Etat de dégradation de l'élément",
  AUDIO_AND_COMMENT: "Commentaire et audio intervenant",
  SUGGESTIONS_AND_ADVICES: "Suggestions et Préconisations",
  DISPLAY_REPAIR_MAP: "Afficher la carte des réparations",
  REPAIRS: "Réparations",
  TEMP: "temporaires",
  PERM: "permanentes",
  DONE_WITH: "réalisées par ",
  TOOLTIP_IMPORT_STATE_AND_COMMENTS: "Importer l'état et les commentaires signalés par l'intervenant",
  TOOLTIP_SIGNAL_NEW_ANSWER: "Signaler une réponse à la question",
  IMPORT_STATE_AND_COMMENTS: "Importer l'état et les commentaires signalés par l'intervenant ou le Responsable Technique",
  TOOLTIP_ASK_QUESTION: "Poser une question au Responsable Technique",

  ALERT_SELECT_DATE: "Sélectionner une date",
  ALERT_SELECT_TEAM: "Sélectionner une équipe",
  PLANNING: "Planning",
  IMPORT: "Importer",

  SAVE: "Enregistrer",
  UNPROGRAM: "Déprogrammer",
  UNPROGRAM_TEAM: "Désaffecter l'équipe",
  CANCEL: "Annuler",
  UNDEFINED_DATE: "Pas de date définie",
  MANUFACTURER: "Fabricant",
  USE: "Utilité",
  READY: "Prêt",
  INCOMPLETE: "Incomplet",
  READY_TO_USE: "Prêt a l'emploi :",
  FILTERS_DISPLAY: "Afficher les filtres à appliquer sur la carte",
  FUNCTION_SOON_AVAILABLE: "Cette fonction sera disponible sous peu",

  YES: "Oui",
  NO: "Non",

  INVALID_LOGIN: "Identifiant ou mot de passe incorrects",
  INDICATE_DANGERS: "Indiquez les dangers",
  TOOLTIP_DISPLAY_DETAILED_HISTORY: "Afficher l'historique détaillé des éléments déclarés du site / Liste des interventions ayant eu lieu sur le site ",

  INTERVENTION_HISTORY: "Historique des interventions sur le site",// / Historique des états des éléments du site",
  AVAILABLE_PRODUCT: "En stock",
  SUPPLIER: "Fournisseur",
  SUPPLY_ON_SITE: "Sur site",
  MESSAGE_FOR_THE_TEAM: "Message pour l'équipe",
  FILL_ALL_INPUTS: "Veuillez remplir tous les champs requis.",
  DECLARED_TIME: "Temps déduit par la pointeuse :",

  CLIENT_NAME: "Nom du Client",
  CLIENT_PHONE: "Numéro de Téléphone du Client",
  CLIENT_MAIL: "Adresse e-Mail du Client",
  CLIENT_FUNCTION: "Fonction de l'interlocuteur",

  SNACKBAR_PASSWORD_ERROR: "Les mots de passe ne coïncident malheureusement pas ! Merci de les saisir de nouveau. 😕",
  SNACKBAR_COMPANY_SUCCESSFULLY_CREATED: "La création de votre compte s'est déroulée avec succès. Bienvenue sur eBat ! 🎉",
  TOOLTIP_ENTER_USERNAME: "Entrez l'identifiant à utiliser pour vous connecter à eBat.",
  TOOLTIP_PASSWORD: "Choisissez un mot de passe le plus sécurisé possible, il peut contenir lettres, chiffres et caractères spéciaux.",
  TOOLTIP_SIREN: "Entrez le numéro de SIREN correspondant à votre entreprise.",
  TOOLTIP_COMPANY_ADDRESS: "Entrez l'adresse de l'entreprise avec ses compléments d'informations si nécessaires (étage, résidence, lieu-dit...)",
  TOOLTIP_ENTER_EMAIL: "Entrez une adresse e-Mail valide.",

  ENTER_USERNAME: "Entrez un nom d'utilisateur",
  ENTER_PASSWORD: "Entrez un mot de passe",
  CONFIRM_PASSWORD: "Confirmez votre mot de passe",

  SIREN: "Numéro de SIREN",
  COMPANY_NAME: "Nom de l'entreprise",
  SERVICE_PROVIDER: "Prestataire",
  TOOLTIP_ENTER_PHONE: "Entrez un numéro de téléphone",
  SUBSCRIBE: "Inscription",
  RETURN_TO_LOGIN: "Revenir à la page de connexion",

  CONTACT_ONSITE: "Nom du contact sur place",
  PHONE_ONSITE: "Numéro de téléphone du contact sur place",
  PHONE2_ONSITE: "Second numéro de téléphone du contact sur place",
  ONSITE_CONTACT_FUNCTION: "Fonction de la personne sur place",

  DURATION: "Durée (HH:MM)",
  SUPPLY_FOR: "Fourniture à prendre pour ",
  DAYS: "Jour(s)",
  SITE_CREATION: "Création de Site",
  SITE_NAME: "Nom du site",
  SITE_SIRET: "Numéro de SIRET du site",

  SAVE_IN_DB: "Sauvegarder dans une base de données",
  IP_OF_DB: "Adresse IP de la  base de données",
  DB_USER: "Utilisateur de la base de données",
  USER_PSSWD: "Mot de passe de l'utilisateur",
  REFRESH: "Rafraîchir",

  CMD_CREATION: "Création de commande",
  REF_NUM: "Ref n°",

  AMOUNT_CURRENCY: "Montant en Euros",
  DEPOSIT_CURRENCY: "Acompte en Euros",

  ATTACHED_DOC: "Documents Administratif",
  ADMIN_DOC_LIST: "Liste des documents administratifs",
  COMMENTS_AND_NOTES: "Commentaires et notes",
  DOCS: "Documents",
  ENGINE: "Nacelle",
  WORK_DOCS_LIST: "Liste des documents de chantier",
  ENGINE_LIST: "Liste des machines de chantier",
  QUOTE_ELEMENT: "Eléments du Devis",
  NO_RIGHTS_PROMPT: "Vous n'avez pas les droits requis pour pouvoir éditer ce champ. Si vous devez les obtenir, contactez votre administrateur.",
  BILL_NUMBER: "Numéro de Facture",
  ESTIMATE_NUMBER: "Numéro de Devis",

  TOOLTIP_TWENTYDAYS_ISAMONTH: "Un mois correspond ici à 20 jours.",
  TOOLTIP_ONEWEEK_ISFIVEDAYS: "Une semaine correspond à 5 jours.",
  TOOLTIP_ONEDAY_ISSEVENHOURS: "Un jour correspond à 7 heures.",

  H2_ADMIN_DOCS: "Documents Administratifs",

  MONTH: "Mois",
  WEEK: "Semaine",
  DAY: "Jour",
  HOUR: "Heure",
  MINUTE: "Minute",


  HOURS: "Heure(s)",
  MINUTES: "Minute(s)",

  ESTIMATED_TIME: "Temps estimé",
  REMOVE_STEP: "Retirer le créneau",
  DEFINE_ESTIMATED_TIME: "Définir la durée d'intervention estimée :",

  SELECT_TEAM: "Sélectionner l'équipe",

  DOC_TYPE: "Type de document",
  ENGINES_AND_OTHER: "Engins et autres dispositifs",
  DEVICE_TYPE: "Type du Dispositif",
  EQUIPMENT_NAME: "Nom du Matériel",
  EQUIPMENT: "Matériel",

  TOOLTIP_CREATE_COMMAND: "Créer une nouvelle commande",
  H2_CREATION_COMMAND: "Création d'une nouvelle Commande",
  H2_CREATION_REQUEST: "Création d'une nouvelle demande",
  MANDATORY_OPERATIONS: "Opérations obligatoires",
  OPERATIONS_TO_SUBMIT: "Opérations à soumettre aux équipes",

  CLIENT_SEARCH: "Rechercher un client",
  SITE_SEARCH: "Rechercher un site",
  TEAM_SEARCH: "Rechercher une équipe",

  CREATE: "Créer",
  REMOVE: "Supprimer",
  SUPPLY_DISPLAY: "Afficher les Documents et Matériels de l'intervention",
  NO_ENTRY_SELECTED: "Aucune entrée n'est sélectionnée.",
  COMPANY_CREATED: "Entreprise créée avec succès",
  H2_SUBSCRIBE: "S'inscrire sur la plateforme eBat",

  TOOLTIP_COMPANY: "Entrer l'adresse de l'entreprise avec ses compléments d'information si nécessaires (étage, résidence, lieu-dit...)",
  TOOLTIP_LOGO_ADD: "Ajouter un logo standard au format JPG ou PNG de moins de 1Mo, telle qu'il devra apparaître dans l'application et dans les rapports PDF que vous pourrez éditer.",
  COMPANY_LOGO: "Logo de l'entreprise",

  H2_CREATE_USER: "Ajouter un nouvel utilisateur",
  ROLE: "Fonction de la personne",
  CHIEF: "Chef",
  DOWNLOAD_REPORT: "Télécharger le rapport",
  MAINTENANCE_TEXT: "Cette nouvelle fonctionnalité arrivera sous peu",
  CREATE_SECTOR: "Créer un secteur",
  NUMBER: "Numéro",
  COUNTRY: "Pays",
  NAME: "Nom",
  REFERENCE: "Référence",

  SNACKBAR_ERASED: "L'élément a bien été supprimé 🗑️",

  PASSWORD: "Mot de passe",


  BILL: "Facture",
  SUPPLY: "Fourniture",

  REF: "Référence",


  OPERATION: "Opération",

  PRODUCT_ID: "Référence interne du produit",
  QUANTITY: "Quantité",


  TO_PREPARE: "A préparer",
  HAZARD: "Danger",

  CUSTOM_ANSWER: "Réponse personnalisée",
  PRODUCT_LIST: "Liste des produits",
  PRODUCTS: "Produits",
  INTERNAL_REF: "Référence interne",
  INCLINOMETER: "Inclinomètre",
  CHOOSE: "Choisir",
  CHOOSE_MANFACTURER: "Choisir un Fabricant",
  CHOOSE_USE: "Choisir un usage",

  ORDER: "Commande",
  FORM: "Formulaire",
  QUESTION: "Question",
  MULTIPLE: "Multiple",
  CUSTOMIZABLE: "Personnalisable",

  OPERATION_TYPE_NAME: "Nom du type d'opération",
  DEFAULT_POSITION: "Position par défaut",
  QUICK_QUOTE: "Estimation Rapide",
  EMERGENCY_ORDER: "Ordre d'urgence",
  EMERGENCY_STATE: "État d'urgence",
  SUPER_ADMIN: "Super Administrateur",
  USER_LIST: "Liste des Utilisateurs",

  INTERACTIONS: "Contacts",
  INTERVENTION: "Intervention",
  DURATION_ESTIMATION: "Estimation horaire",
  REPORT: "Rapport",
  PLEASE_LOGIN: "Votre compte est déconnecté ! Veuillez rafraîchir la page et vous reconnecter.",

  INTERVENTIONS: "Intervention",
  TOOLTIP_DISPLAY_INTERVENTIONS_MAP: "Afficher la carte des interventions",
  TOOLTIP_EDIT_PREF_SITE: "Modifier les sites préférés",
  TOOLTIP_DISPLAY_PLANNING: "Afficher le planning",
  ENTER_ROLES: "Fonctions de l'utilisateur...",
  ACTIVATED: "Utilisateur activé",
  ADD_DESTINATION_TO_OPERATION: "Veuillez ajouter la destination vers laquelle vous souhaitez exporter l'opération.",

  REPAIR: "Réparation",
  GOOD_CONDITION: "Bon état",
  WORN_OUT: "Usé",
  TO_REPAIR: "À réparer",
  TO_BE_REDONE: "À refaire",
  HAZARDOUS: "Dangereux",

  CLEAN: "Propre",
  LIGHT: "Léger",
  AVERAGE: "Moyen",
  IMPORTANT: "Important",
  AUTHORIZE_GOOGLE_AGENDA: "Autoriser la synchronisation avec votre agenda Google",

  LOGIN: "Se Connecter",
  LOGIN_BUTTON: "Connexion",
  LOGIN_DETAILS: "Identifiants",

  CONTACT_NAME: "Nom du Contact",
  CONTACT_STATUS: "Fonction du contact",

  URL_IMG: "Image Satellite",

  EXTERNAL_ID: "ID Site du Client",
  DATES: "Dates",

  SEALANT: "Mastic",
  SILICON: "Silicone",
  BITUBAND: "Bitubande",
  RESIN: "Résiné",
  BITUMEN: "Bitume",
  FASTENING: "Fixation",

  INTERVENTION_SHEET: "Fiche d'intervention",
  INTERVENTION_PLACE: "Lieu d'intervention",
  INTERVENTION_PURPOSE: "Objet de l'intervention",
  INTERVENTION_SUMMARY: "Résumé de l'intervention",

  INTERVENTION_TABLE: "Liste des interventions",
  CLIENT_TABLE: "Liste des prestataires",
  SITE_TABLE: "Liste des sites",
  REQUEST_TABLE: "Liste des demandes",
  HOURS_PAGE: "Relevé des heures et des trajets",
  PARAMETERS: "Paramètres de l'application",

  CONNECT: "Déconnexion du compte",

  CAPTION: "Légende",
  REPORTING: "Signalement",

  NO_REPAIR_WASDONE: "Aucune réparation effectuée",

  EMERGENCY: "Urgent",
  LESS_THAN_ONEYEAR: "À réaliser dans moins d'un an",
  BETWEEN_ONE_AND_THREE: "Entre un et trois ans",
  MORE_THAN_THREE: "Plus de trois ans",
  WITHOUT: "Sans",
  SIGNING_PERSONS_NAME: "Nom signataire",
  SIGNING_PERSONS_FUNCTION: "Fonction du signataire",

  CRITERIA_LIST: "Filtres de recherche",
  CSV: "Télécharger la liste d'interventions filtrées au format CSV",
  GENERAL_STATS: "Statistiques générales",
  DIAG: "Diagnostic",
  INTERVENTION_REPORT: "Rapport d'intervention",
  TRAVEL_TIME: "Appliquer les temps de trajets",
  TRAVEL_TIME_ERROR: "Erreur sur le trajet ",

  START: "Début",

  INTERVENTION_SHEET_CAPS: "FICHE D'INTERVENTION",

  ADDRESS_NOT_FOUND: "Le site recherché est introuvable, veuillez réessayer.",
  SEARCH_ADDRESS: "Rechercher une adresse",
  NO_CHOICE: "Aucun choix",

  RESULTS: "Résultat(s)",
  PAGE: "Page",
  OF: "sur",
  RESULTS_PER_PAGE: "Résultats par page",
  NO_RESULT: "Aucun résultat trouvé, veuillez réessayer.",
  ESTIMATE: "Devis",

  ERROR_MESSAGE: "Une erreur est survenue, veuillez faire retour et rafraîchir la page.",
  PLANINGSENTENCE1: "L'intervention planifiée au",
  PLANINGSENTENCE2: " n'a pas pu être enregistrée, veuillez vérifiez les dates entrées.",
  ELEMENTS_QUANTITY: "Quantité d'éléments",
  UNMOVABLE_PARTITON: "Partition d'intervention non-déplaçable ",
  WORK_FORCE: "Main d'œuvre",
  ADD_EVENT: "Définir le créneau à planifier",

  TOTAL: "Total",
  ORDERED_COMMAND: "Nombre de missions",
  SAV: "+SAV",
  PREPARING: "En préparation",
  RUNNING: "En cours",
  ACHIEVED: "Terminées",
  REPORTED: "Rapports disponibles",
  CANCELED: "Annulées",
  DONE_BY_CALENDAR: "Nombre d'interventions par jours calendaires",
  DONE_BY_BUSINESS: "Nombre d'interventions par jours ouvrés",
  CUMUL_BY_CALENDAR: "Cumul par jours calendaires",
  CUMUL_BY_BUSINESS: "Cumul par jours ouvrés",
  J0: "J0",
  J1: "J+1",
  J2: "J+2",
  J3: "J+3",
  J4: "J4+",

}
export default constants
